@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

@font-face {
  font-family: 'HendersonBCGSans-Regular';
  src: url('assets/fonts/HendersonBCGSans-Regular.woff2') format('woff2'), url('assets/fonts/HendersonBCGSans-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'HendersonBCGSans-Light';
  src: url('assets/fonts/HendersonBCGSans-Light.woff2') format('woff2'), url('assets/fonts/HendersonBCGSans-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'HendersonBCGSans-Thin';
  src: url('assets/fonts/HendersonBCGSans-Thin.woff2') format('woff2'), url('assets/fonts/HendersonBCGSans-Thin.woff') format('woff');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'HendersonBCGSans-Bold';
  src: url('assets/fonts/HendersonBCGSans-Bold.woff2') format('woff2'), url('assets/fonts/HendersonBCGSans-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'HendersonBCGSerif-Regular';
  src: url('assets/fonts/HendersonBCGSerif-Regular.woff2') format('woff2'), url('assets/fonts/HendersonBCGSerif-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'HendersonBCGSerif-Bold';
  src: url('assets/fonts/HendersonBCGSerif-Bold.woff2') format('woff2'), url('assets/fonts/HendersonBCGSerif-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: $sans-reg;
  color: $gray--darker;
  line-height: 1.50em;
  -webkit-font-smoothing: antialiased;

  // the following style is added to show blur on entire screen when modal is opened
  &.dimmable.dimmed {
    .ui.page.modals.dimmer.transition.visible.active {
      z-index: 10000;
    }
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  list-style-type: none;
}

h1 {
  font-family: $sans-bold;
  font-size: 30px;
  color: $gray--darker;
  line-height: 1.25em;
}

h2 {
  font-family: $sans-bold;
  font-size: 22.5px;
  color: $gray--darker;
  line-height: 1.25em;
}

h3 {
  font-family: $sans-reg;
  font-size: 17.5px;
  color: $gray--darker;
  line-height: 1.25em;
}

#main {
  margin-top: 57px;
}

// carry over from deleting DocViz components

.ui.bottom.left.popup {
  padding-left: 0;
  padding-right: 0;
}

.dropdown-menu__list {
  min-width: 160px;

  .separator {
    margin: 2px 0;
    height: 2px;
    opacity: 0.38;
    background-image: linear-gradient(to right, rgba(220, 220, 220, 0.29), rgba(211, 211, 211, 0.82) 25%, $light-gray2 48%, rgba(183, 183, 183, 0.53) 78%, rgba(183, 183, 183, 0.24));
  }
}

.dropdown-menu__item {
  text-align: left;
  width: 100%;
  padding: 8px 12px;

  &:hover {
    background-image: linear-gradient(to left, rgba(86, 201, 137, 0.13), rgba(63, 160, 173, 0.13));
    box-shadow: 0 2px 4px 0 rgba(163, 163, 163, 0.13);
  }
}

.ui.button.dropdown-menu__btn {
  outline: 0;
  border: 0;
  font-family: $sans-bold;
  color: $gray--darker;
  font-size: 12px;
  line-height: 1;
  padding: 4px 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: $transparent;
  text-transform: capitalize;
  height: auto;
  letter-spacing: 0;

  @media (max-width: map-get($grid-breakpoints, md)) {
    letter-spacing: 0;
    font-family: $sans-bold;
  }

  &:hover {
    opacity: 1;
    background: transparent;
  }
}

.dropdown-menu__btn-icon {
  margin: 0 16px 0 0;
}

.status-msg-link {
  color: $gvgray;
  text-decoration: underline;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.status-msg-link:hover {
  color: $bcg-green;
  font-weight: 600;
  text-decoration: underline;
}

.status-msg-link .status-msg-icon {
  margin-bottom: -2px;
  width: 14px;
  margin-left: 5px;
  filter: invert(45%) sepia(2%) saturate(7%) hue-rotate(326deg) brightness(96%) contrast(85%);
}

.status-msg-link:hover .status-msg-icon {
  filter: brightness(0) saturate(100%) invert(37%) sepia(9%) saturate(4087%) hue-rotate(110deg) brightness(94%) contrast(82%);
}

.rc-agent {
  ul {
    margin-top: 12px;
    
    li {
      line-height: 24px;
      margin-bottom: 15px;
    }
  }
}